









































































import { Component, Prop } from 'vue-property-decorator';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ICause } from '@/interfaces/causes';

@Component({ components: { ConfirmationModal } })
export default class SymptomsTable extends AppComponent {
@Prop({ default: [] }) public value: ICause[];
@Prop({ default: true }) public showActions: boolean;
@Prop({ default: true }) public showSearch: boolean;
@Prop({ default: false }) public yieldActions: boolean;
@Prop({ default: 10 }) public itemsPerPage: number;

  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Description',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
    },
  ];

  public search =  '';

  /**
   * Edit the selected cause from the list
   * If the yieldActions prop is true, emit the id of the cause to be edited will
   * be went to the parent component by a 'edit' event
   * otherwise, the user will be redirected to the edit cause page
   * @param id the id of the cause to be edited
   */
  public editCause(causeId: string) {
    if (this.yieldActions) {
      this.$emit('cause:edited', causeId);
    } else {
      this.$router.push({
        name: 'main-content-causes-edit',
        params: { causeId },
      });
    }
  }

  /**
   * Delete a cause and refresh the screen
   */
  public async deleteCause(causeId: string) {
    this.$emit('cause:deleted', causeId);
  }
}
